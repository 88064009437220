<template>
  <div class="admin appointments">

    <header class="admin-header cf">
      <h1>
        <router-link to="/admin/locations">Standorte</router-link>
        <p>
          {{ location.name }}
        </p>
      </h1>
    </header>

    <div v-if="loading" class="loading-wrap">
      <span class="loading-spinner"></span>
    </div>
    <div v-else>

      <div class="row row-gutter-20">
        <div class="col-12">
          <div class="content-wrap">
            <h3>Standort</h3>

            <div class="form-wrap select-wrap">
              <label for="status">Status</label>
              <select v-model="location.status" class="form-input">
                <option key="hidden" value="hidden">Ausgeblendet</option>
                <option key="visible" value="visible">Sichtbar</option>
              </select>
            </div>

            <div class="form-wrap">
              <label for="name" class="form-label">Name</label>
              <input v-model="location.name" class="form-input input-grey" type="text" name="name" id="name">
            </div>

            <div class="form-wrap">
              <label for="description">Beschreibung</label>
              <textarea v-model="location.description" id="description" name="description" rows="4" class="form-input"></textarea>
            </div>

            <div class="form-wrap">
              <label for="info_body">Info oder Ankündigung</label>
              <textarea v-model="location.info_body" id="info_body" name="info_body" rows="3" class="form-input"></textarea>
            </div>

            <div class="form-wrap">
              <label for="name" class="form-label">Termin E-Mail per CC an</label>
              <input v-model="location.cc_email" class="form-input input-grey" type="text" name="name" id="name">
            </div>

            <div class="form-wrap select-wrap">
              <label for="status">Ausdruck für Bezahlung</label>
              <select v-model="location.payment_type" class="form-input">
                <option key="bon_printer" value="bon_printer">Bon-Drucker (bei den Terminen)</option>
                <option key="laser_printer" value="laser_printer">DIN A4 Quittung (bei den Quittungen)</option>
              </select>
            </div>

            <div class="form-wrap" style="margin: 20px 0 10px 0">
              <input v-model="location.cc_email_description" type="checkbox" id="cc_email_description" name="cc_email_description" style="display:inline-block;vertical-align:middle;margin-right:5px">
              <label for="cc_email_description" style="display:inline-block;vertical-align:middle;margin:0">Nur wenn das Feld "Beschreibung" ausgefüllt wird?</label>
            </div>

            <div class="form-wrap" style="margin: 20px 0 10px 0">
              <input v-model="location.feature_waiting_line" type="checkbox" id="feature_waiting_line" name="feature_waiting_line" style="display:inline-block;vertical-align:middle;margin-right:5px">
              <label for="feature_waiting_line" style="display:inline-block;vertical-align:middle;margin:0">Wird die Warteschlangen-Funktion genutzt?</label>
            </div>

            <p style="margin-bottom:0;text-align:right">
              <a @click="update_location" class="button button-red">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="floppy-disk" class="svg-inline--fa fa-floppy-disk" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path fill="currentColor" d="M433.1 129.1l-83.9-83.9C342.3 38.32 327.1 32 316.1 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V163.9C448 152.9 441.7 137.7 433.1 129.1zM224 416c-35.34 0-64-28.66-64-64s28.66-64 64-64s64 28.66 64 64S259.3 416 224 416zM320 208C320 216.8 312.8 224 304 224h-224C71.16 224 64 216.8 64 208v-96C64 103.2 71.16 96 80 96h224C312.8 96 320 103.2 320 112V208z"></path>
                </svg>
                <span>Daten speichern ›</span>
              </a>
            </p>

          </div>
        </div>
        <div class="col-12">
          <div class="content-wrap content-termine">
            <h3>Terminplanung</h3>

            <p style="font-size:12px;line-height:1.4;background:rgb(244, 248, 255);color:#084298;padding:14px;border:1px dashed #b6d4fe;">Die Terminplanung basiert auf der Anzahl an Terminen, die parallel erstellt werden können. Der Grundwert findet sich unter dem Punkt "Langfristig". Sollte kein Wochen- oder Tagesbasierter-Eintrag existieren, greift immer der langfristige Grundwert. Der Tageswert hat die höchste Prio und überschreibt den Wochen- und Grundwert.</p>

            <h4>Tagesbasiert</h4>
            <p class="sub">Die Anzahl der Termine, die parallel an dem hinterlegten Tag erstellt werden können.</p>
            <p style="margin-top:0">
              <a @click="open_day_entry" class="button button-light-gray button-small" style="font-size: 0.7rem;padding: 0.3rem 0.8rem;">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>
                <span>Eintrag anlegen</span>
              </a>
            </p>

            <div v-if="scheduling_entries_day.length == 0">
              <p style="font-size:12px;line-height:1.4;background:rgb(244, 248, 255);color:#084298;padding:14px;border:1px dashed #b6d4fe;">Aktuell sind keine tagesbasierten Einträge vorhanden.</p>
            </div>
            <div v-else>
              <p v-for="entry in scheduling_entries_day" v-bind:key="entry.id" class="entry-item">
                <span v-if="entry.entry_val == 1">{{ entry.day_formatted }}: {{ entry.entry_val }} Termin parallel</span>
                <span v-else>{{ entry.day_formatted }}: {{ entry.entry_val }} Termine parallel</span>
                <svg @click="delete_entry(entry)" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
              </p>
            </div>

            <h4>Wochenbasiert</h4>
            <p class="sub">Die Anzahl der Termine, die parallel in der hinterlegten Woche erstellt werden können.</p>
            <p style="margin-top:0">
              <a @click="open_week_entry" class="button button-light-gray button-small" style="font-size: 0.7rem;padding: 0.3rem 0.8rem;">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>
                <span>Eintrag anlegen</span>
              </a>
            </p>

            <div v-if="scheduling_entries_week.length == 0">
              <p style="font-size:12px;line-height:1.4;background:rgb(244, 248, 255);color:#084298;padding:14px;border:1px dashed #b6d4fe;">Aktuell sind keine wochenbasierten Einträge vorhanden.</p>
            </div>
            <div v-else>
              <p v-for="entry in scheduling_entries_week" v-bind:key="entry.id" class="entry-item">
                <span v-if="entry.entry_val == 1">KW {{ entry.cw }}: {{ entry.entry_val }} Termin parallel</span>
                <span v-else>KW {{ entry.cw }}: {{ entry.entry_val }} Termine parallel</span>
                <svg @click="delete_entry(entry)" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
              </p>
            </div>

            <h4>Langfristig</h4>
            <p class="sub">Die Anzahl der Termine, die parallel in den nächsten {{ location.days_ahead }} Tagen erstellt werden können.</p>

            <div class="form-wrap">
              <label for="slot_amount" class="form-label">Wie viele Termine sollen parallel hinterlegt werden können?</label>
              <input v-model="location.slot_amount" class="form-input input-grey" type="text" name="slot_amount" id="slot_amount">
            </div>

            <div class="form-wrap">
              <label for="slot_interval" class="form-label">Terminvereinbarung Intervall (in min.)</label>
              <input v-model="location.slot_interval" class="form-input input-grey" type="text" name="slot_interval" id="slot_interval">
            </div>

            <!--<div class="form-wrap">
              <label for="days_ahead" class="form-label">Wie viele Tage im Voraus sollen Termine gebucht werden können?</label>
              <input v-model="location.days_ahead" class="form-input input-grey" type="text" name="days_ahead" id="days_ahead">
            </div>-->

            <p style="margin-bottom:0;text-align:right">
              <a @click="update_location" class="button button-red">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="floppy-disk" class="svg-inline--fa fa-floppy-disk" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path fill="currentColor" d="M433.1 129.1l-83.9-83.9C342.3 38.32 327.1 32 316.1 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V163.9C448 152.9 441.7 137.7 433.1 129.1zM224 416c-35.34 0-64-28.66-64-64s28.66-64 64-64s64 28.66 64 64S259.3 416 224 416zM320 208C320 216.8 312.8 224 304 224h-224C71.16 224 64 216.8 64 208v-96C64 103.2 71.16 96 80 96h224C312.8 96 320 103.2 320 112V208z"></path>
                </svg>
                <span>Daten speichern ›</span>
              </a>
            </p>

          </div>

          <div class="content-wrap">
            <h3>Öffungszeiten</h3>

            <div v-if="opening_hours && opening_hours.length == 0" class="alert alert-info" style="margin-bottom:0">
              <p>Noch keine Öffnungszeiten hinterlegt.</p>
            </div>
            <div v-else>

              <ul class="content-list">
                <li v-for="hour in opening_hours" v-bind:key="hour.id">
                  <span v-if="hour.working_day == 'monday'">Montag:</span>
                  <span v-if="hour.working_day == 'tuesday'">Dienstag:</span>
                  <span v-if="hour.working_day == 'wednesday'">Mittwoch:</span>
                  <span v-if="hour.working_day == 'thursday'">Donnerstag:</span>
                  <span v-if="hour.working_day == 'friday'">Freitag:</span>
                  <span v-if="hour.working_day == 'saturday'">Samstag:</span>
                  <span v-if="hour.working_day == 'sunday'">Sonntag:</span>
                  {{ hour.start_formatted }} Uhr – {{ hour.end_formatted }} Uhr

                  <svg @click="delete_hour(hour)" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" class="svg-inline--fa fa-xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                    <path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"></path>
                  </svg>
                </li>
              </ul>
            </div>

            <p style="text-align:right;margin-bottom:0">
              <a @click="modal_add_opening_hour = true" class="button button-red">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" class="svg-inline--fa fa-plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path fill="currentColor" d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"></path>
                </svg>
                <span>Öffnungszeit hinzufügen ›</span>
              </a>
            </p>

          </div>

          <div class="content-wrap">
            <h3>Servicezeiten</h3>

            <div v-if="service_hours && service_hours.length == 0" class="alert alert-info" style="margin-bottom:0">
              <p>Noch keine Servicezeiten hinterlegt.</p>
            </div>
            <div v-else>

              <ul class="content-list">
                <li v-for="hour in service_hours" v-bind:key="hour.id">
                  <span v-if="hour.working_day == 'monday'">Montag:</span>
                  <span v-if="hour.working_day == 'tuesday'">Dienstag:</span>
                  <span v-if="hour.working_day == 'wednesday'">Mittwoch:</span>
                  <span v-if="hour.working_day == 'thursday'">Donnerstag:</span>
                  <span v-if="hour.working_day == 'friday'">Freitag:</span>
                  <span v-if="hour.working_day == 'saturday'">Samstag:</span>
                  <span v-if="hour.working_day == 'sunday'">Sonntag:</span>
                  {{ hour.start_formatted }} Uhr – {{ hour.end_formatted }} Uhr

                  <svg @click="delete_service_hour(hour)" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" class="svg-inline--fa fa-xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                    <path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"></path>
                  </svg>
                </li>
              </ul>
            </div>

            <p style="text-align:right;margin-bottom:0">
              <a @click="modal_add_service_hour = true" class="button button-red">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" class="svg-inline--fa fa-plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path fill="currentColor" d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"></path>
                </svg>
                <span>Servicezeit hinzufügen ›</span>
              </a>
            </p>

          </div>
        </div>
      </div>
    </div>

    <vue-final-modal classes="modal-wrap" content-class="modal-inner" v-model="modal_add_opening_hour">
      <svg @click="modal_add_opening_hour = false" aria-hidden="true" focusable="false" data-prefix="far" data-icon="times-circle" class="modal-close svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"></path>
      </svg>
      <div class="modal-head">
        <h3>Öffnungszeit hinzufügen</h3>
      </div>
      <div class="modal-body">

        <div class="form-wrap select-wrap">
          <label for="working_day">Wochentag</label>
          <select v-model="opening_hour.working_day" class="form-input">
            <option key="monday" value="monday">Montag</option>
            <option key="tuesday" value="tuesday">Dienstag</option>
            <option key="wednesday" value="wednesday">Mittwoch</option>
            <option key="thursday" value="thursday">Donnerstag</option>
            <option key="friday" value="friday">Freitag</option>
            <option key="saturday" value="saturday">Samstag</option>
            <option key="sunday" value="sunday">Sonntag</option>
          </select>
        </div>

        <div class="row row-gutter-20">
          <div class="col-12">
            <div class="form-wrap">
              <label for="start" class="form-label">Von</label>
              <input v-model="opening_hour.start" class="form-input input-grey" type="time" name="start" id="start">
            </div>
          </div>
          <div class="col-12">
            <div class="form-wrap">
              <label for="end" class="form-label">Bis</label>
              <input v-model="opening_hour.end" class="form-input input-grey" type="time" name="end" id="end">
            </div>
          </div>
        </div>

      </div>
      <div class="modal-meta">
        <a @click="create_opening_hour" class="button button-red button-100">Öffnungszeit erstellen ›</a>
      </div>
    </vue-final-modal>

    <vue-final-modal classes="modal-wrap" content-class="modal-inner" v-model="modal_add_service_hour">
      <svg @click="modal_add_service_hour = false" aria-hidden="true" focusable="false" data-prefix="far" data-icon="times-circle" class="modal-close svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"></path>
      </svg>
      <div class="modal-head">
        <h3>Servicezeit hinzufügen</h3>
      </div>
      <div class="modal-body">

        <div class="form-wrap select-wrap">
          <label for="working_day">Wochentag</label>
          <select v-model="service_hour.working_day" class="form-input">
            <option key="monday" value="monday">Montag</option>
            <option key="tuesday" value="tuesday">Dienstag</option>
            <option key="wednesday" value="wednesday">Mittwoch</option>
            <option key="thursday" value="thursday">Donnerstag</option>
            <option key="friday" value="friday">Freitag</option>
            <option key="saturday" value="saturday">Samstag</option>
            <option key="sunday" value="sunday">Sonntag</option>
          </select>
        </div>

        <div class="row row-gutter-20">
          <div class="col-12">
            <div class="form-wrap">
              <label for="start" class="form-label">Von</label>
              <input v-model="service_hour.start" class="form-input input-grey" type="time" name="start" id="start">
            </div>
          </div>
          <div class="col-12">
            <div class="form-wrap">
              <label for="end" class="form-label">Bis</label>
              <input v-model="service_hour.end" class="form-input input-grey" type="time" name="end" id="end">
            </div>
          </div>
        </div>

      </div>
      <div class="modal-meta">
        <a @click="create_service_hour" class="button button-red button-100">Servicezeit erstellen ›</a>
      </div>
    </vue-final-modal>

    <vue-final-modal classes="modal-wrap" content-class="modal-inner" v-model="modal_add_day_entry">
      <svg @click="modal_add_day_entry = false" aria-hidden="true" focusable="false" data-prefix="far" data-icon="times-circle" class="modal-close svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"></path>
      </svg>
      <div class="modal-head">
        <h3>Tageseintrag hinzufügen</h3>
      </div>
      <div class="modal-body">

        <div class="form-wrap">
          <label for="day" class="form-label">Bitte den Tag auswählen:</label>
          <v-date-picker v-model="day_entry.day" :first-day-of-week="2" locale="de" color="red">
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="bg-white border px-2 py-1 rounded form-input"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker>
        </div>

        <div class="form-wrap">
          <label for="entry_val" class="form-label">Wie viele Termine sollen parallel an diesem Tag erlaubt sein?</label>
          <input v-model="day_entry.entry_val" class="form-input" type="text" name="entry_val" id="entry_val">
        </div>

      </div>
      <div class="modal-meta">
        <a @click="create_day_entry" class="button button-red button-100">Tageseintrag erstellen ›</a>
      </div>
    </vue-final-modal>

    <vue-final-modal classes="modal-wrap" content-class="modal-inner" v-model="modal_add_week_entry">
      <svg @click="modal_add_week_entry = false" aria-hidden="true" focusable="false" data-prefix="far" data-icon="times-circle" class="modal-close svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"></path>
      </svg>
      <div class="modal-head">
        <h3>Wocheneintrag hinzufügen</h3>
      </div>
      <div class="modal-body">

        <div class="form-wrap">
          <label for="cw" class="form-label">Woche</label>
          <input v-model="week_entry.cw" class="form-input input-grey" type="text" name="cw" id="cw">
        </div>

        <div class="form-wrap">
          <label for="entry_val_week" class="form-label">Wie viele Termine sollen parallel in dieser Woche erlaubt sein?</label>
          <input v-model="week_entry.entry_val" class="form-input" type="text" name="entry_val_week" id="entry_val_week">
        </div>

      </div>
      <div class="modal-meta">
        <a @click="create_week_entry" class="button button-red button-100">Wocheneintrag erstellen ›</a>
      </div>
    </vue-final-modal>

  </div>
</template>

<script>
export default {
  name: 'admin_location',
  data () {
    return {
      loading: true,
      location: {},
      opening_hours: [],
      opening_hour: {},
      modal_add_opening_hour: false,
      service_hours: [],
      service_hour: {},
      modal_add_service_hour: false,
      scheduling_entries_week: [],
      scheduling_entries_day: [],
      modal_add_day_entry: false,
      day_entry: {
        scheduling_type: "is_day",
        day: new Date(),
        entry_val: null
      },
      modal_add_week_entry: false,
      week_entry: {
        scheduling_type: "is_day",
        cw: null,
        entry_val: null
      }
    }
  },
  methods: {
    async get_data() {
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/locations/'+this.$route.params.uuid, { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.location = response.data.location;
      })
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/locations/'+this.$route.params.uuid+'/opening_hours', { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.opening_hours = response.data.opening_hours;
      })
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/locations/'+this.$route.params.uuid+'/service_hours', { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.service_hours = response.data.service_hours;
      })
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/locations/'+this.$route.params.uuid+'/scheduling_entries', { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.scheduling_entries_day = [];
        this.scheduling_entries_week = [];
        response.data.scheduling_entries.forEach(entry => {
          if (entry.scheduling_type == "is_day") {
            this.scheduling_entries_day.push(entry);
          } else if (entry.scheduling_type == "is_week") {
            this.scheduling_entries_week.push(entry);
          }
        })
      })
      this.loading = false;
    },
    update_location() {
      this.$http.patch(process.env.VUE_APP_BASE_API+'/admin/locations/'+this.$route.params.uuid, this.location, { headers: { Authorization: this.$store.getters.get_token } })
      .then(() => {
        this.get_data();
        this.modal_edit = false;
        this.$notify({
          title: "Erfolgreich gespeichert.",
          type: "success"
        });
      })
      .catch(error => {
        error.response.data.errors.forEach(value => {
          this.$notify({
            title: value,
            type: "error"
          });
        });
      });
    },
    create_opening_hour() {
      this.$http.post(process.env.VUE_APP_BASE_API+'/admin/locations/'+this.$route.params.uuid+'/opening_hours', this.opening_hour, { headers: { Authorization: this.$store.getters.get_token } })
      .then(() => {
        this.get_data();
        this.modal_add_opening_hour = false;
        this.opening_hour = {};
        this.$notify({
          title: "Erfolgreich erstellt.",
          type: "success"
        });
      })
      .catch(error => {
        error.response.data.errors.forEach(value => {
          this.$notify({
            title: value,
            type: "error"
          });
        });
      });
    },
    delete_hour(hour) {
      if(confirm("Wirklich löschen?")) {
        this.$http.delete(process.env.VUE_APP_BASE_API+'/admin/locations/'+this.$route.params.uuid+'/opening_hours/'+hour.id,  { headers: { Authorization: this.$store.getters.get_token } })
        .then(() => {
          this.get_data();
          this.$notify({
            title: "Erfolgreich gelöscht.",
            type: "success"
          });
        })
        .catch(error => {
          error.response.data.errors.forEach(value => {
            this.$notify({
              title: value,
              type: "error"
            });
          });
        });
      }
    },
    create_service_hour() {
      this.$http.post(process.env.VUE_APP_BASE_API+'/admin/locations/'+this.$route.params.uuid+'/service_hours', this.service_hour, { headers: { Authorization: this.$store.getters.get_token } })
      .then(() => {
        this.get_data();
        this.modal_add_service_hour = false;
        this.service_hour = {};
        this.$notify({
          title: "Erfolgreich erstellt.",
          type: "success"
        });
      })
      .catch(error => {
        error.response.data.errors.forEach(value => {
          this.$notify({
            title: value,
            type: "error"
          });
        });
      });
    },
    delete_service_hour(hour) {
      if(confirm("Wirklich löschen?")) {
        this.$http.delete(process.env.VUE_APP_BASE_API+'/admin/locations/'+this.$route.params.uuid+'/service_hours/'+hour.id,  { headers: { Authorization: this.$store.getters.get_token } })
        .then(() => {
          this.get_data();
          this.$notify({
            title: "Erfolgreich gelöscht.",
            type: "success"
          });
        })
        .catch(error => {
          error.response.data.errors.forEach(value => {
            this.$notify({
              title: value,
              type: "error"
            });
          });
        });
      }
    },
    open_day_entry() {
      this.day_entry = {
        scheduling_type: "is_day",
        day: new Date(),
        entry_val: 1
      }
      this.modal_add_day_entry = true;
    },
    open_week_entry() {
      let now = new Date();
      let onejan = new Date(now.getFullYear(), 0, 1);
      let week = Math.ceil((((now.getTime() - onejan.getTime()) / 86400000) + onejan.getDay() + 1) / 7);
      this.week_entry = {
        scheduling_type: "is_week",
        cw: week,
        entry_val: 1
      }
      this.modal_add_week_entry = true;
    },
    create_day_entry() {
      this.$http.post(process.env.VUE_APP_BASE_API+'/admin/locations/'+this.$route.params.uuid+'/scheduling_entries', this.day_entry, { headers: { Authorization: this.$store.getters.get_token } })
      .then(() => {
        this.get_data();
        this.$notify({
          title: "Erfolgreich erstellt.",
          type: "success"
        });
        this.modal_add_day_entry = false;
      })
      .catch(error => {
        error.response.data.errors.forEach(value => {
          this.$notify({
            title: value,
            type: "error"
          });
        });
      });
    },
    create_week_entry() {
      this.$http.post(process.env.VUE_APP_BASE_API+'/admin/locations/'+this.$route.params.uuid+'/scheduling_entries', this.week_entry, { headers: { Authorization: this.$store.getters.get_token } })
      .then(() => {
        this.get_data();
        this.$notify({
          title: "Erfolgreich erstellt.",
          type: "success"
        });
        this.modal_add_week_entry = false;
      })
      .catch(error => {
        error.response.data.errors.forEach(value => {
          this.$notify({
            title: value,
            type: "error"
          });
        });
      });
    },
    delete_entry(entry) {
      if(confirm("Wirklich löschen?")) {
        this.$http.delete(process.env.VUE_APP_BASE_API+'/admin/locations/'+this.$route.params.uuid+'/scheduling_entries/'+entry.id, { headers: { Authorization: this.$store.getters.get_token } })
        .then(() => {
          this.get_data();
          this.$notify({
            title: "Erfolgreich gelöscht.",
            type: "success"
          });
        })
        .catch(error => {
          error.response.data.errors.forEach(value => {
            this.$notify({
              title: value,
              type: "error"
            });
          });
        });
      }
    }
  },
  mounted () {
    this.get_data();
  }
}
</script>

<style lang="scss">
  .content-termine {

    h4 {
      margin: 20px 0 0 0;
      color: #9C2128;
    }

    p.sub {
      margin: 4px 0 8px 0;
      font-size: 12px;
    }
  }

  .entry-item {
    border: 1px solid #ddd;
    background: #f9f9f9;
    border-radius: 5px;
    margin: 2px 0;
    padding: 10px;
    position: relative;
    font-size: 14px;

    svg {
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 10px;
      width: 20px;
    }

    svg:hover {
      opacity: 0.8;
    }
  }

</style>
